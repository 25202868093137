// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/superAdmin';
// import router from "../../../router/index";
// import store from '@/store';

const state = {
    // Additional state if needed
    getTransactions: '',
}

const mutations = {
    SET_TRANSACTION_DATA(state, data) {
        state.getTransactions = data
    },
}

const actions = {
    // Additional actions if needed
    getTransactions({ commit }) {
        // return
        // return new Promise((resolve, reject) => {
        axios
            .get(axios.defaults.baseURL + apis.GET_TRANSACTION, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "ngrok-skip-browser-warning": "69420",
                },
            })
            .then((res) => {
                console.log('getTransactions---res !!', res)
                if (res.data.data) {
                    commit('SET_TRANSACTION_DATA', res.data.data)
                }
                else {
                    commit('SET_TRANSACTION_DATA', res.data.message)
                }
            })
            .catch((err) => {
                console.log('getTransactions err', err)
            })
        // })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
};