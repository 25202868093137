// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getInvoices: '',
    getFolderById: '',
}

const mutations = {
    SET_INVOICES_DATA(state, data) {
        state.getInvoices = data
    },
    SET_FOLDER_BY_ID(state, data) {
        state.getFolderById = data
    },
}

const actions = {
    // Additional actions if needed
    getInvoices({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_INVOICE + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getInvoices---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_INVOICES_DATA', res.data.data)
                        }
                        else{
                           commit('SET_INVOICES_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getInvoices err', err)
                    })
            // })
    },

    // getFolderById({ commit }, id) {
    //     // return
    //     // return new Promise((resolve, reject) => {
    //         axios
    //             .get(axios.defaults.baseURL + apis.GET_FOLDER_BY_ID + id, {
    //                 headers: {
    //                     Authorization: "Bearer " + localStorage.getItem("token"),
    //                     "ngrok-skip-browser-warning": "69420",
    //                 },
    //             })
    //             .then((res) => {
    //                 console.log('getFolderById---res !!', res)
    //                 if(res.data.data){
    //                     commit('SET_FOLDER_BY_ID', res.data.data)
    //                 }
    //                 else{
    //                    commit('SET_FOLDER_BY_ID', res.data.message)
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log('getFolderById err', err)
    //             })
    //     // })
    // },

    createInvoice({dispatch}, payload) {
        console.log("payload--", payload);
        // return
           // Add common fields
           var formData = new FormData();
            formData.append("adminId", payload.adminId || "");
            formData.append("userId", payload.userId || "");

                   
            // Handle multipleInvoice-specific fields
            if (payload.multipleInvoice === "multipleInvoice") {
                    // Handle unitId (array of units)
                payload.unitId.forEach(unit => {
                    formData.append("unitId[]", unit._id);
                });
                formData.append("multipleInvoice[invoiceDate]", payload.invoiceDate || "");
                formData.append("multipleInvoice[paymentDate]", payload.paymentDate || "");

                // Append each item dynamically
                if (Array.isArray(payload.items)) {
                payload.items.forEach((item, index) => {
                    formData.append(`multipleInvoice[items][${index}][title]`, item.title || "");
                    formData.append(`multipleInvoice[items][${index}][calculationType]`, item.calculationType || "");
                    formData.append(`multipleInvoice[items][${index}][amount]`, item.amount || "");
                    formData.append(`multipleInvoice[items][${index}][allocateTo]`, item.allocateTo || "");
                });
                }
            }

            // Append singleInvoice fields if present
            if (payload.singleInvoice === "singleInvoice") {
                formData.append("unitId[]", payload.unitId || "");

                if (Array.isArray(payload.items)) {
                    payload.items.forEach((item, index) => {
                        formData.append(`singleInvoice[items][${index}][item]`, item.item || "");
                        formData.append(`singleInvoice[items][${index}][allocateTo]`, item.allocateTo || "");
                        formData.append(`singleInvoice[items][${index}][cost]`, item.cost || "");
                    });
                }
            }

            // Append externalInvoice fields if present
            if (payload.externalInvoice === 'externalInvoice') {
                // formData.append("unitId[]",  []);

                formData.append("externalInvoice[company]", payload.company || "");
                formData.append("externalInvoice[name]", payload.name || "");
                formData.append("externalInvoice[email]", payload.email || "");
                formData.append("externalInvoice[address]", payload.address || "");
                formData.append("externalInvoice[allocateTo]", payload.allocateTo || "");
                if (Array.isArray(payload.items)) {
                    payload.items.forEach((item, index) => {
                        formData.append(`externalInvoice[items][${index}][item]`, item.item || "");
                        formData.append(`externalInvoice[items][${index}][cost]`, item.cost || "");
                    });
                }
            }
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.CREATE_INVOICE, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("createInvoice res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);

                    dispatch('')
                })
                .catch((err) => {
                    console.log("createInvoice err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    // deleteFolderById({dispatch }, payload) {
    //     // return
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .delete(axios.defaults.baseURL + apis.DELETE_FOLDER_BY_ID + payload.id, {
    //                 headers: {
    //                     Authorization: "Bearer " + localStorage.getItem("token"),
    //                     "ngrok-skip-browser-warning": "69420",
    //                 },
    //             })
    //             .then((res) => {
    //                 console.log('deleteFolderById---res !!', res)
    //                 resolve(res)
    //                 dispatch("checkErrorAndSendToast", [
    //                     res.data.message,
    //                     "success",
    //                 ]);
                     
    //                 dispatch('getAllFolders', payload.adminId)
    //                 dispatch('getFolderById', payload.folderId)
    //             })
    //             .catch((err) => {
    //                 reject(err)
    //                 console.log('deleteFolderById err', err)
    //                 dispatch("checkErrorAndSendToast", [
    //                     err.response,
    //                     "error",
    //                 ]);
    //             })
    //     })
    // },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };