<template>
      <!-- ============================ Sidebar Start ============================ -->
      <nav :class="['sidebar', 'sidebar-offcanvas', { 'open': isOpen }]" id="sidebar">
        <ul class="nav">
          <li class="nav-item" :class="{'active': $route.path === '/super-admin'}">
            <RouterLink class="nav-link" to="/super-admin">
              <i class="mdi mdi-view-dashboard menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </RouterLink>
          </li>
          <li class="nav-item nav-category">Pages</li>
          <li class="nav-item" :class="{'active': $route.path === '/users' || $route.path === '/view-dashboard'}">
            <RouterLink class="nav-link" to="/users">
              <i class="menu-icon mdi mdi-account-group"></i>
              <span class="menu-title">Users</span>
            </RouterLink>
          </li>
          <li class="nav-item" :class="{'active': $route.path === '/subscriptions'}">
            <RouterLink class="nav-link" to="/subscriptions">
              <i class="menu-icon mdi mdi-playlist-check"></i>
              <span class="menu-title">Subscriptions</span>
            </RouterLink>
          </li>
          <li class="nav-item" :class="{'active': $route.path === '/products'}">
            <RouterLink class="nav-link" to="/products">
              <i class="menu-icon mdi mdi-package-variant-closed"></i>
              <span class="menu-title">Products</span>
            </RouterLink>
          </li>
          <li class="nav-item" :class="{'active': $route.path === '/bot'}">
            <RouterLink class="nav-link" to="/bot">
              <i class="menu-icon mdi mdi-google-cardboard"></i>
              <span class="menu-title">Train Bot</span>
            </RouterLink>
          </li>
          <li class="nav-item" :class="{'active': $route.path === '/transactions'}">
            <RouterLink class="nav-link" to="/transactions">
              <i class="menu-icon mdi mdi-cash-fast"></i>
              <span class="menu-title">Transactions</span>
            </RouterLink>
          </li>
        </ul>
      </nav>
      <!-- ============================ Sidebar End  ============================ -->
</template>

<script>
  import { mapState } from "vuex";
export default {
  name: "SidebarPage",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
  },

  computed: {
        ...mapState({
          user: (state) => state.auth.user,
        }),
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.activePage a{
  color: white !important;
}

.open {
  right: 0 !important; /* Sidebar slides into view */
}
</style>
