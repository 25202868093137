// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";
// import store from '@/store';

const state = {
    // Additional state if needed
    getSubscription: '',
}

const mutations = {
    SET_USER_SUBSCRIPTION_DATA(state, data) {
        state.getSubscription = data
    },
}

const actions = {
    // Additional actions if needed
    getUserSubscription({ commit }, adminId) {
        // return
        // return new Promise((resolve, reject) => {
        axios
            .get(axios.defaults.baseURL + apis.GET_USER_SUBSCRIPTIONS + adminId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "ngrok-skip-browser-warning": "69420",
                },
            })
            .then((res) => {
                console.log('getUserSubscription---res !!', res)
                if (res.data.data) {
                    commit('SET_USER_SUBSCRIPTION_DATA', res.data.data)
                }
                else {
                    commit('SET_USER_SUBSCRIPTION_DATA', res.data.message)
                }
            })
            .catch((err) => {
                console.log('getUserSubscription err', err)
            })
        // })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
};