// import axios from "axios";
import router from "@/router/index";

export default function authTwo({ to, from, next, store }) {
  var token = localStorage.getItem("token");
  var isAuthenticated = Boolean(token);
  var user = JSON.parse(localStorage.getItem("user"));
  console.log("isAuthenticated from authtwo--", from);
  console.log("next from authTwo--", next);
  // console.log("isAuthenticated middelware--", isAuthenticated);
  // console.log("isAuthenticated store--", store);
  // console.log("isAuthenticated next--", next);
  // console.log("isAuthenticated to--", to);
  // console.log("isAuthenticated meta.portal--", to.meta.portal);

  // Check if the user is authenticated and navigating to Homepage
  if (isAuthenticated && to.meta.portal === "Homepage") {
    if (store.getters.auth) {
      // Handle expired subscription status
      if (user.subscriptionStatus === "Expired") {
        return next();
      }

      // Redirect based on user role
      if (user.role === "AD" || ['SM', 'CH', 'TR', 'SCR', 'SC', 'PM', 'OW', 'TN'].some(r => user?.role?.includes(r))) {
        router.push("/admin")
      }

      if (user.role === "SAD") {
        router.push("/super-admin")
      }
    }
  }

  // If conditions are not met, proceed to the next middleware or route
  return next();


}
