// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getUnit: '',
    unitById: '',
}

const mutations = {
    SET_UNIT_DATA(state, data) {
        state.getUnit = data
    },
    SET_UNIT_BY_ID(state, data) {
        state.unitById = data
    },
}

const actions = {
    // Additional actions if needed
      getUnit({ commit }, id) {
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_UNIT_ADMIN_ID + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getUnit---res !!', res)
                        resolve(res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_UNIT_DATA', res.data.data)
                        }
                        else{
                           commit('SET_UNIT_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        reject(err)
                        console.log('getUnit err', err)
                    })
            })
    },

    getUnitById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_UNIT_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getUnitById---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_UNIT_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_UNIT_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getMemberById err', err)
                })
        // })
    },

    addUnit({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("adminId", payload.adminId);
        formData.append("UnitNumber", payload.UnitNumber);
        formData.append("Address", payload.Address);
        formData.append("Suburb", payload.Suburb);
        formData.append("StateofTerritory", payload.StateofTerritory);
        formData.append("PostalCode", payload.PostalCode);
        formData.append("UnitEquivalentValue", payload.UnitEquivalentValue);
        formData.append("Notes", payload.Notes);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_UNIT, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addUnit res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getUnit', payload.adminId)
                })
                .catch((err) => {
                    console.log("addUnit err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    editUnit({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("UnitNumber", payload.UnitNumber);
        formData.append("Address", payload.Address);
        formData.append("Suburb", payload.Suburb);
        formData.append("StateofTerritory", payload.StateofTerritory);
        formData.append("PostalCode", payload.PostalCode);
        formData.append("UnitEquivalentValue", payload.UnitEquivalentValue);
        formData.append("Notes", payload.Notes);
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.EDIT_UNIT_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editUnit res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getUnit', payload.adminId)
                })
                .catch((err) => {
                    console.log("editUnit err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    deleteUnitById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_UNIT_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteUnitById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getUnit', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteUnitById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };