import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import auth from '../middleware/auth'
import authTwo from '../middleware/authTwo'
import middlewarePipeline from './middlewarePipeline'

import AdminLayout from '@/layout/AdminLayout.vue'
import SuperAdminLayout from '@/layout/SuperAdminLayout.vue'
import UserLayout from '@/layout/UserLayout.vue';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: UserLayout,
    meta: {
      middleware: [
          auth,
          authTwo
      ],
      portal: 'Homepage'
    },
    children: [
      {
        path: "",
        name: "LandingPage",
        component: () => import("../views/Landing/Index.vue"),
        meta: { authRequired: false, title: 'Home Page | Strata Management' },
      },
      {
        path: "/pricing",
        name: "PricingPage",
        component: () => import("../views/Landing/Pricing.vue"),
        meta: { authRequired: false, title: 'Pricing | Strata Management' },
      },
    ]
  },
  {
    path: '/admin',
    name: 'HomePage',
    component: AdminLayout,
    meta: {
      middleware: [
          auth
      ],
      portal: 'Admin'
    },
    children: [
      {
        path: "",
        name: "DashboardPage",
        component: () => import("../views/admin/Dashboard.vue"),
        meta: { authRequired: false, title: 'Home | Strata Management' },
      },
      {
        path: "/units",
        name: "UnitsPage",
        component: () => import("../views/admin/Units.vue"),
        meta: { authRequired: false, title: 'Unit | Strata Management' },
      },
      {
        path: "/member",
        name: "MemberPage",
        component: () => import("../views/admin/member/Member.vue"),
        meta: { authRequired: false, title: 'Member | Strata Management' },
      },
      {
        path: "/member-details=:id",
        name: "MemberDetailsPage",
        component: () => import("../views/admin/member/Member_details.vue"),
        meta: { authRequired: false, title: 'Member Details | Strata Management' },
      },
      {
        path: "/announcements",
        name: "AnnouncementsPage",
        component: () => import("../views/admin/notice/Announcements.vue"),
        meta: { authRequired: false, title: 'Announcements | Strata Management' },
      },
      {
        path: "/announcement-details=:id",
        name: "AnnouncementDetailsPage",
        component: () => import("../views/admin/notice/Announcement_Details.vue"),
        meta: { authRequired: false, title: 'Announcement Details | Strata Management' },
      },
      {
        path: "/messaging",
        name: "MessagingPage",
        component: () => import("../views/admin/Messaging.vue"),
        meta: { authRequired: false, title: 'Messaging | Strata Management' },
      },
      {
        path: "/meeting",
        name: "MeetingPage",
        component: () => import("../views/admin/meeting/Meetings.vue"),
        meta: { authRequired: false, title: 'Meetings | Strata Management' },
      },
      {
        path: "/meeting-details=:id",
        name: "MeetingDetailsPage",
        component: () => import("../views/admin/meeting/Meeting_Details.vue"),
        meta: { authRequired: false, title: 'Meeting Details | Strata Management' },
      },
      {
        path: "/edit-agenda=:id",
        name: "EditAgendaPage",
        component: () => import("../views/admin/meeting/Edit_Agenda.vue"),
        meta: { authRequired: false, title: 'Edit Agenda | Strata Management' },
      },
      {
        path: "/edit-minutes=:id",
        name: "EditMinutesPage",
        component: () => import("../views/admin/meeting/Edit_Minutes.vue"),
        meta: { authRequired: false, title: 'Edit Minutes | Strata Management' },
      },
      {
        path: "/polling",
        name: "PollingPage",
        component: () => import("../views/admin/Polling.vue"),
        meta: { authRequired: false, title: 'Polling | Strata Management' },
      },
      {
        path: "/finance",
        name: "FinancePage",
        component: () => import("../views/admin/finance/Finance.vue"),
        meta: { authRequired: false, title: 'Finance | Strata Management' },
      },
      {
        path: "/tax-invoice",
        name: "TaxInvoicePage",
        component: () => import("../views/admin/finance/inner/TaxInvoice.vue"),
        meta: { authRequired: false, title: 'Tax Invoice | Strata Management' },
      },
      {
        path: "/venues",
        name: "VenuesPage",
        component: () => import("../views/admin/venues/Venues.vue"),
        meta: { authRequired: false, title: 'Venues | Strata Management' },
      },
      {
        path: "/pinboard",
        name: "PinboardPage",
        component: () => import("../views/admin/Pinboard.vue"),
        meta: { authRequired: false, title: 'Pinboard | Strata Management' },
      },
      {
        path: "/document",
        name: "DocumentPage",
        component: () => import("../views/admin/document/Documents.vue"),
        meta: { authRequired: false, title: 'Documents | Strata Management' },
      },
      // {
      //   path: "/document-pinboard",
      //   name: "DocumentPinboardPage",
      //   component: () => import("../views/admin/document/Document_Pinboard.vue"),
      //   meta: { authRequired: false, title: 'Document Pinboard | Strata Management' },
      // },
      {
        path: "/sub-document-:id",
        name: "SubDocumentPage",
        component: () => import("../views/admin/document/Sub_Document.vue"),
        meta: { authRequired: false, title: 'Documents | Strata Management' },
      },
      {
        path: "/link",
        name: "LinkPage",
        component: () => import("../views/admin/Links.vue"),
        meta: { authRequired: false, title: 'Link | Strata Management' },
      },
      {
        path: "/user-subscriptions",
        name: "SubscriptionUserPage",
        component: () => import("../views/admin/Subscription.vue"),
        meta: { authRequired: false, title: 'Subscriptions | Strata Management' },
      },
      {
        path: "/user-transactions",
        name: "TransactionUserPage",
        component: () => import("../views/admin/Transaction.vue"),
        meta: { authRequired: false, title: 'Transactions | Strata Management' },
      },
    ]
  },
  {
    path: '/super-admin',
    name: 'SuperHomePage',
    component: SuperAdminLayout,
    meta: {
      middleware: [
        auth
      ],
      portal: 'SuperAdmin'
    },
    children: [
      {
        path: "",
        name: "SuperDashboardPage",
        component: () => import("../views/superAdmin/Dashboard.vue"),
        meta: { authRequired: false, title: 'Home | Strata Management' },
      },
      {
        path: "/users",
        name: "UsersPage",
        component: () => import("../views/superAdmin/Users.vue"),
        meta: { authRequired: false, title: 'Users | Strata Management' },
      },
      {
        path: "/view-dashboard",
        name: "ViewAdminDashboardPage",
        component: () => import("../views/superAdmin/View_Admin_Dashboard.vue"),
        meta: { authRequired: false, title: 'View Admin Dashboard | Strata Management' },
      },
      {
        path: "/subscriptions",
        name: "SubscriptionsPage",
        component: () => import("../views/superAdmin/Subscriptions.vue"),
        meta: { authRequired: false, title: 'Subscriptions | Strata Management' },
      },
      {
        path: "/products",
        name: "ProductsPage",
        component: () => import("../views/superAdmin/products/Products.vue"),
        meta: { authRequired: false, title: 'Products | Strata Management' },
      },
      {
        path: "/product-details",
        name: "ProductDetailsPage",
        component: () => import("../views/superAdmin/products/Product_Details.vue"),
        meta: { authRequired: false, title: 'Product Details | Strata Management' },
      },
      {
        path: "/active-subscription",
        name: "ActiveSubscriptionPage",
        component: () => import("../views/superAdmin/products/Active_Subscription.vue"),
        meta: { authRequired: false, title: 'Active Subscription | Strata Management' },
      },
      {
        path: "/bot",
        name: "TrainBotPage",
        component: () => import("../views/superAdmin/bot/Bot.vue"),
        meta: { authRequired: false, title: 'Train Bot | Strata Management' },
      },
      {
        path: "/transactions",
        name: "TransactionsPage",
        component: () => import("../views/superAdmin/Transactions.vue"),
        meta: { authRequired: false, title: 'Transactions | Strata Management' },
      },
    ]
  },

  //Payment page for stripe
  {
    path: "/payment",
    name: "PaymentPage",
    component: () => import("../views/Landing/stripeCheckout.vue"),
    meta: { authRequired: false, title: 'Payment | Strata Managemen' },
  },

  // Login page
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: { authRequired: false, title: 'Login | Strata Management' },
  },
  // Register page
  {
    path: '/complete-registration',
    name: 'Complete Registration',
    component: () => import(/* webpackChunkName: "about" */ '../views/CompleteRegistration.vue'),
    meta: { authRequired: false, title: 'Complete Register | Strata Management' },
  },
  {
    path: '/complete-strata-registration',
    name: 'Complete Strata Registration',
    component: () => import(/* webpackChunkName: "about" */ '../views/createStrata.vue'),
    meta: { authRequired: false, title: 'Create Strata | Strata Management' },
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    meta: { authRequired: false, title: 'Admin Register | Strata Management' },
  },
  // Register page
  {
    path: '/member-register=:id',
    name: 'MemberRegisterPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/MemberRegister.vue'),
    meta: { authRequired: false, title: 'Member Register | Strata Management' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log("to", to);
  console.log("from", from);
  console.log("next", next());
  document.title = to.meta.title || 'Strata Management';

  window.scrollTo(0, 0);

  if (!to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware
  console.log('router * middleware', middleware);
  const context = {
      to,
      from,
      next,
      store
  }

   return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })
});

export default router
