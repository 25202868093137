<template>
     <!-- partial:partials/_navbar.html -->
     <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <!-- <div class="me-3">
          <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-bs-toggle="minimize">
            <span class="icon-menu"></span>
          </button>
        </div> -->
        <div class="ms-3">
          <router-link class="navbar-brand brand-logo" to="/admin">
            <img src="images/logo.png" alt="logo" />
          </router-link>
          <!-- <router-link class="navbar-brand brand-logo-mini" to="/admin">
            <img src="images/logo-mini.svg" alt="logo" />
          </router-link> -->
          <button class=" ms-2 d-lg-none align-self-center" @click="toggle" type="button">
            <span class="mdi mdi-menu"></span>
          </button>
        </div>
      </div>
      <div v-if="User" class="navbar-menu-wrapper d-flex align-items-top">
        <ul class="navbar-nav">
          <li class="nav-item fw-semibold d-none d-lg-block ms-0">
            <div class="d-flex align-items-center">
              <h1 class="welcome-text d-block">{{ greetingMessage }}, <span class="text-black fw-bold">{{User?.firstName +' ' + User?.lastName}}</span></h1>
              <small class="ms-3">
                <strong>Role - {{ typeof User?.role === 'string' ? User?.role : User?.role?.join(', ') }}</strong>
              </small>
            </div>
            <!-- <h3 class="welcome-sub-text d-block">Your performance summary this week </h3> -->
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li v-if="checkSuperAdmin != null" @click="backToSuperAdmin()" class="nav-item">
            <span class="h5 text-primary backTo">
              <loader v-if="loaderSuperAdmin" />
              <span class="back" v-if="!loaderSuperAdmin">Back to Super Admin</span>
            </span>
          </li>
          <li v-if="checkAdmin != null" @click="backToAdmin()" class="nav-item">
            <span class="h5 text-primary backTo">
              <loader v-if="loaderAdmin" />
              <span class="back" v-if="!loaderAdmin">Back to Admin</span>
            </span>
          </li>
          <!-- <li class="nav-item dropdown d-none d-lg-block">
            <a class="nav-link dropdown-bordered dropdown-toggle dropdown-toggle-split" id="messageDropdown" href="#"
              data-bs-toggle="dropdown" aria-expanded="false"> Select Category </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="messageDropdown">
              <a class="dropdown-item py-3">
                <p class="mb-0 fw-medium float-start">Select category</p>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item">
                <div class="preview-item-content flex-grow py-2">
                  <p class="preview-subject ellipsis fw-medium text-dark">Bootstrap Bundle </p>
                  <p class="fw-light small-text mb-0">This is a Bundle featuring 16 unique dashboards</p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-item-content flex-grow py-2">
                  <p class="preview-subject ellipsis fw-medium text-dark">Angular Bundle</p>
                  <p class="fw-light small-text mb-0">Everything you’ll ever need for your Angular projects</p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-item-content flex-grow py-2">
                  <p class="preview-subject ellipsis fw-medium text-dark">VUE Bundle</p>
                  <p class="fw-light small-text mb-0">Bundle of 6 Premium Vue Admin Dashboard</p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-item-content flex-grow py-2">
                  <p class="preview-subject ellipsis fw-medium text-dark">React Bundle</p>
                  <p class="fw-light small-text mb-0">Bundle of 8 Premium React Admin Dashboard</p>
                </div>
              </a>
            </div>
          </li> -->
          <!-- <li class="nav-item d-none d-lg-block">
            <div id="datepicker-popup" class="input-group date datepicker navbar-date-picker">
              <span class="input-group-addon input-group-prepend border-right">
                <span class="icon-calendar input-group-text calendar-icon"></span>
              </span>
              <input type="text" class="form-control">
            </div>
          </li> -->
          <!-- <li class="nav-item">
            <form class="search-form" action="#">
              <i class="icon-search"></i>
              <input type="search" class="form-control" placeholder="Search Here" title="Search here">
            </form>
          </li> -->
          <!-- <li class="nav-item dropdown">
            <a class="nav-link count-indicator" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
              <i class="icon-bell"></i>
              <span class="count"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="notificationDropdown">
              <a class="dropdown-item py-3 border-bottom">
                <p class="mb-0 fw-medium float-start">You have 4 new notifications </p>
                <span class="badge badge-pill badge-primary float-end">View all</span>
              </a>
              <a class="dropdown-item preview-item py-3">
                <div class="preview-thumbnail">
                  <i class="mdi mdi-alert m-auto text-primary"></i>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject fw-normal text-dark mb-1">Application Error</h6>
                  <p class="fw-light small-text mb-0"> Just now </p>
                </div>
              </a>
              <a class="dropdown-item preview-item py-3">
                <div class="preview-thumbnail">
                  <i class="mdi mdi-lock-outline m-auto text-primary"></i>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject fw-normal text-dark mb-1">Settings</h6>
                  <p class="fw-light small-text mb-0"> Private message </p>
                </div>
              </a>
              <a class="dropdown-item preview-item py-3">
                <div class="preview-thumbnail">
                  <i class="mdi mdi-airballoon m-auto text-primary"></i>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject fw-normal text-dark mb-1">New user registration</h6>
                  <p class="fw-light small-text mb-0"> 2 days ago </p>
                </div>
              </a>
            </div>
          </li> -->
          <!-- <li class="nav-item dropdown">
            <a class="nav-link count-indicator" id="countDropdown" href="#" data-bs-toggle="dropdown"
              aria-expanded="false">
              <i class="icon-mail icon-lg"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="countDropdown">
              <a class="dropdown-item py-3">
                <p class="mb-0 fw-medium float-start">You have 7 unread mails </p>
                <span class="badge badge-pill badge-primary float-end">View all</span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <img src="images/faces/face10.jpg" alt="image" class="img-sm profile-pic">
                </div>
                <div class="preview-item-content flex-grow py-2">
                  <p class="preview-subject ellipsis fw-medium text-dark">Marian Garner </p>
                  <p class="fw-light small-text mb-0"> The meeting is cancelled </p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <img src="images/faces/face12.jpg" alt="image" class="img-sm profile-pic">
                </div>
                <div class="preview-item-content flex-grow py-2">
                  <p class="preview-subject ellipsis fw-medium text-dark">David Grey </p>
                  <p class="fw-light small-text mb-0"> The meeting is cancelled </p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <img src="images/faces/face1.jpg" alt="image" class="img-sm profile-pic">
                </div>
                <div class="preview-item-content flex-grow py-2">
                  <p class="preview-subject ellipsis fw-medium text-dark">Travis Jenkins </p>
                  <p class="fw-light small-text mb-0"> The meeting is cancelled </p>
                </div>
              </a>
            </div>
          </li> -->
          
          <li class="nav-item dropdown d-lg-block user-dropdown">
            <a class="nav-link" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
              <img v-if="User?.profileLink == ''" class="img-xs rounded-circle" src="images/faces/face8.jpg" alt="Profile image">
              <img v-if="User?.profileLink != ''" class="img-xs rounded-circle" :src="User?.profileLink" alt="Profile image"> 
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
              <div class="dropdown-header text-center">
                <!-- <img class="img-md rounded-circle" src="images/faces/face8.jpg" alt="Profile image"> -->
                <p class="mb-1 mt-3 fw-semibold">{{User?.firstName + ' ' + User?.lastName}}</p>
                <p class="fw-light text-muted mb-0">{{User?.email}}</p>
              </div>
              <a class="dropdown-item"><i class="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> My
                Profile </a>
              <router-link v-if="User?.role === 'AD'" to="/user-subscriptions" class="dropdown-item">
                <i class="dropdown-item-icon mdi mdi-calendar-check-outline text-primary me-2"></i> My subscriptions
              </router-link>
              <router-link v-if="User?.role === 'AD'" to="/user-transactions" class="dropdown-item">
                <i class="dropdown-item-icon mdi mdi-cash-100 text-primary me-2"></i> My transactions
              </router-link>
              <a @click="logout()" class="dropdown-item">
                <i class="dropdown-item-icon mdi mdi-power text-primary me-2"></i>
                <span>Logout</span>
                <loader v-if="loader" />
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <!-- partial -->
</template>
    
<script>
import loader from '../loader.vue';

export default {
  name: "HeaderPage",
  props: {
    msg: String,
  },

  components: {
        loader
    },

    data(){
        return{
            loaderSuperAdmin: false,
            loaderAdmin: false,
            currentHour: new Date().getHours(), // Gets the current hour (0-23)
            checkSuperAdmin: '',
            checkAdmin: '',
        }
    },

    created(){
      this.checkSuperAdmin = localStorage.getItem('superAdminToken')
      this.checkAdmin = localStorage.getItem('adminToken')
    },

    computed:{
      User(){
        return this.$store.state.auth.user;
      },

      greetingMessage() {
        if (this.currentHour < 12) {
          return "Good Morning";
        } else if (this.currentHour < 18) {
          return "Good Afternoon";
        } else {
          return "Good Evening";
        }
      },
    },

    methods: {
      logout(){
        this.loader = true;
        this.$store.dispatch('logout',{
          userToken: localStorage.getItem('token'),
          name: 'admin',
        })
        .then((res)=>{
            console.log("res", res);
            this.loader = false;
        })
        .catch((err)=>{ 
            console.log("err", err);
            this.loader = false;
        })
      },

      toggle() {
        this.$emit('toggleSidebar');
      },



      backToSuperAdmin(){
        this.loaderSuperAdmin = true;
        // return
        this.$store
          .dispatch("BackToSuperAdmin")
          .then(() => {
            this.loaderSuperAdmin = false;
            localStorage.removeItem('adminToken');
          })
          .catch(() => {
            this.loaderSuperAdmin = false;
          });
      },

      backToAdmin(){
        this.loaderAdmin = true;
        // return
        this.$store
          .dispatch("BackToAdmin")
          .then(() => {
            this.loaderAdmin = false;
            localStorage.removeItem('adminToken');
          })
          .catch(() => {
            this.loaderAdmin = false;
          });
      }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.backTo{
  cursor: pointer;
}
.back{
  background: #000000;
  padding: 10px;
  border-radius: 5px;
  color: white;
}
</style>
                                    