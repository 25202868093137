<template>
      <!-- ============================ Sidebar Start ============================ -->
       <nav :class="['sidebar', 'sidebar-offcanvas', { 'open': isOpen }]" id="sidebar">
        <ul class="nav">
          <li v-if="['AD', 'SM', 'CH'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/admin'}">
            <RouterLink class="nav-link" to="/admin">
              <i class="mdi mdi-view-dashboard menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </RouterLink>
          </li>
          <li class="nav-item nav-category">Pages</li>
          <li v-if="['AD', 'SM', 'CH'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/units'}">
            <RouterLink class="nav-link" to="/units">
              <i class="menu-icon mdi mdi-office-building-marker"></i>
              <span class="menu-title">Units</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/member'}">
            <RouterLink class="nav-link" to="/member">
              <i class="menu-icon mdi mdi-account-group"></i>
              <span class="menu-title">Members</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH', 'TR', 'SCR', 'SC', 'PM', 'OW', 'TN'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/announcements'}">
            <RouterLink class="nav-link" to="/announcements">
              <i class="menu-icon mdi mdi-bulletin-board"></i>
              <span class="menu-title">Announcements</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH', 'TR', 'SCR', 'SC', 'PM', 'OW', 'TN'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/messaging'}">
            <RouterLink class="nav-link" to="/messaging">
              <i class="menu-icon mdi mdi-forum"></i>
              <span class="menu-title">Messaging</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH', 'TR', 'SCR', 'SC', 'PM', 'OW', 'TN'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/meeting'}">
            <RouterLink class="nav-link" to="/meeting">
              <i class="menu-icon mdi mdi-file-document"></i>
              <span class="menu-title">Meetings</span>
            </RouterLink>
          </li>          
          <li v-if="['AD', 'SM', 'CH', 'SCR', 'SC', 'PM', 'OW', 'TN'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/polling'}">
            <RouterLink class="nav-link" to="/polling">
              <i class="menu-icon mdi mdi-arrow-decision-auto-outline"></i>
              <span class="menu-title">Polling</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH', 'TR'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/finance'}">
            <RouterLink class="nav-link" to="/finance">
              <i class="menu-icon mdi mdi-finance"></i>
              <span class="menu-title">Finance</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH', 'TR', 'SCR', 'SC', 'PM', 'OW', 'TN'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/venues'}">
            <RouterLink class="nav-link" to="/venues">
              <i class="menu-icon mdi mdi-home-city-outline"></i>
              <span class="menu-title">Venues</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH', 'SC', 'PM', 'OW', 'TN'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/pinboard'}">
            <RouterLink class="nav-link" to="/pinboard">
              <i class="menu-icon mdi mdi-pin"></i>
              <span class="menu-title">Pinboard</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/document'}">
            <RouterLink class="nav-link" to="/document">
              <i class="menu-icon mdi mdi-folder-file-outline"></i>
              <span class="menu-title">Files</span>
            </RouterLink>
          </li>
          <li v-if="['AD', 'SM', 'CH'].some(r => user?.role?.includes(r))" class="nav-item" :class="{'active': $route.path === '/link'}">
            <RouterLink class="nav-link" to="/link">
              <i class="menu-icon mdi mdi-link"></i>
              <span class="menu-title">Links</span>
            </RouterLink>
          </li>
        </ul>
        

        <div v-if="!user" class="my-5">
          <LoaderPage />
        </div>
      </nav>
      <!-- ============================ Sidebar End  ============================ -->
</template>

<script>
  import { mapState } from "vuex";
  import LoaderPage from "../loaderPage.vue";
export default {
  name: "SidebarPage",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    LoaderPage
  },

  computed: {
        ...mapState({
          user: (state) => state.auth.user,
        }),
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.activePage a{
  color: white !important;
}

.open {
  left: 0 !important; /* Sidebar slides into view */
}
.sidebar-offcanvas{
  z-index: 1042;
}
</style>
