import { createStore } from 'vuex';
// import App from "../../src/app.vue";
//auth and toast
import auth from './auth.js';
import VerifyErrorsAndToasts from './verifyErrorsAndToasts.js';

//Admin
import member from './module/admin/member.js';
import announcement from './module/admin/announcement.js';
import unit from './module/admin/unit.js';
import venue from './module/admin/venue.js';
import polling from './module/admin/polling.js';
import message from './module/admin/message.js';
import pinboard from './module/admin/pinboard.js';
import links from './module/admin/links.js';
import document from './module/admin/document.js';
import meeting from './module/admin/meeting.js';
import dashboard from './module/admin/dashboard.js';
import finance from './module/admin/finance.js';
import chatBot from './module/admin/bot.js';
import userSubscription from './module/admin/subscription.js';
import userTransaction from './module/admin/transaction.js';

//Super Admin
import dashboardSuperAdmin from './module/superAdmin/dashboard.js';
import bot from './module/superAdmin/bot.js';
import subscription from './module/superAdmin/subscription.js';
import transaction from './module/superAdmin/transaction.js';


export default createStore({
  modules: {
    // auth and toast
    auth,
    VerifyErrorsAndToasts,

    // Admin
    member,
    announcement,
    unit,
    venue,
    polling,
    message,
    pinboard,
    links,
    document,
    meeting,
    dashboard,
    finance,
    chatBot,
    userSubscription,
    userTransaction,

    // Super Admin
    dashboardSuperAdmin,
    bot,
    subscription,
    transaction,
  },
});