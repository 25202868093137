export const apis = {
    //For super Admin
    ADMIN: 'admin',
    SWITCH_TO_ADMIN: 'auth/superadmin/login-as-admin',
    BACK_TO_SUPERADMIN: 'auth/superadmin/revert-to-superadmin',

    GET_SUBSCRIPTION: 'subscription',
    GET_TRANSACTION: 'subscription/transaction',

    //BOT
    GET_BOT_QNA: 'chatbot/qna',
    GET_BOT_QNA_BY_ID: 'chatbot/find-qna/',
    FIND_BOT_QNA_BY_ID: 'chatbot/find-qna/',
    ADD_BOT_QNA: 'chatbot/add-qna',
    UPDATE_BOT_QNA: 'chatbot/update-qna/',
    DELETE_BOT_QNA: 'chatbot/delete-qna/',
};