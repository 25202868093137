// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed

}

const mutations = {

}

const actions = {
    // Additional actions if needed

    sendToChatBot({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("message", payload.message);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.SEND_CHATBOT, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("sendToChatBot res", res);
                    resolve(res);
                    dispatch('')
                })
                .catch((err) => {
                    console.log("sendToChatBot err", err);
                    reject(err);
                });
        });
    },


}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };